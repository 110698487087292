import ShopRoundedIcon from '@mui/icons-material/ShopRounded';
import PermMediaRounded from '@mui/icons-material/PermMediaRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import AddToQueueRoundedIcon from '@mui/icons-material/AddToQueueRounded';
import SlideshowRoundedIcon from '@mui/icons-material/SlideshowRounded';

import { ROUTES } from 'constants/routes';

export const TOOLS_LINKS = {
  campaigns: {
    to: ROUTES.campaigns,
    Icon: ShopRoundedIcon,
    text: 'campaigns.title',
  },
  campaignCreate: {
    to: ROUTES.campaignCreate,
    Icon: AddToQueueRoundedIcon,
    text: 'campaign.titleNew',
  },
  campaignEdit: {
    to: ROUTES.campaignCreate,
    Icon: AddToQueueRoundedIcon,
    text: 'campaign.titleEdit',
    hidden: true,
  },
  media: {
    to: ROUTES.media,
    Icon: PermMediaRounded,
    text: 'media.title',
  },
  campaignMediaUpload: {
    to: ROUTES.campaignMediaUpload,
    Icon: PermMediaRounded,
    text: 'media.labels.campaignUpload',
    hidden: true,
  },
  upload: {
    to: ROUTES.upload,
    Icon: CloudUploadRoundedIcon,
    text: 'upload.title',
  },
  slides: {
    to: ROUTES.slides,
    Icon: SlideshowRoundedIcon,
    text: 'slides.title',
    hidden: true,
  },
};
